<template>
  <div class="row mb-4">
    <div class="col-12 text-left">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="sendEmail">
            Send email
          </a>
        </li>
        <li>
          <a class="nav-link" href="#" @click.prevent="sendDocument">
            Send document
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "EMSubMenu",
  emits: ['selected'],
  methods: {
    sendEmail() {
      this.$emit('selected', 'send-email');
    },
    sendDocument() {
      this.$emit('selected', 'send-document');
    },
  },
}
</script>
<style lang="scss"></style>