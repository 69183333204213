<template>
  <div class="row">
    <div class="flex-column w-100">
      <div class="row no-gutters w-100 col-12 text-left align-items-center mr-0">
        <div class="col-3 text-left">
          <h5>Select document:</h5>
        </div>
        <!--      <div class="col-9 text-left align-items-center">-->
        <!--        <SelectInput-->
        <!--            name="document_template"-->
        <!--            label=""-->
        <!--            class="confirmationYes"-->
        <!--            :with-blank-option="true"-->
        <!--            :options="documentList"-->
        <!--            @selected="handleSelected"-->
        <!--        />-->
        <!--      </div>-->
      </div>
      <div class="row no-gutters p-3">
        <Form
            id="dataForm"
            :validation-schema="schemaDoc"
            class="text-left"
            @submit="handleSend"
        >
          <SelectInput
              :options="documentList"
              :with-blank-option="true"
              class="confirmationYes"
              label=""
              name="document_template"
              @selected="handleSelected"
          />
          <TextInput
              v-if="selectedDocument"
              ref="send_to"
              :label="`Send ${selectedDocument} to:`"
              :value="setDefaultEmail"
              name="email"
              type="text" @updated="handleSendToUpdate"
          />
          <button v-if="selectedDocument" class="fabric-btn fabric-btn-submit">
            Send
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "EMDocumentSelect",
  setup() {
    const schemaDoc = Yup.object().shape({
      document_template: Yup.string().required(),
      email: Yup.string().email().required(),
    });
    return {
      schemaDoc
    };
  },
  data: () => ({
    selectedDocument: null,
    documentList: [
      {
        id: "letter-9",
        name: "Letter 9 - Information request & LOA - Known"
      }, {
        id: "letter-9a",
        name: "Letter 9a - Information request & LOA - Unknown"
      }, {
        id: "letter-10c",
        name: "Letter 10c - Cover Letter & Blank LOA for member to sign"
      }, {
        id: "letter-11",
        name: "Letter 11 - Information request - Chaser for provider"
      },
    ]
  }),
  methods: {
    ...mapActions([
      "sendDocument",
    ]),
    ...mapMutations(["setLoading", "setNotification"]),
    handleSelected(value) {
      this.selectedDocument = value;
    },
    async handleSend(params) {
      params.tpuuid = this.$route.params.tpuuid

      let response = await this.sendDocument(params).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Sending document error!",
          content: this.formulateErrorString(err)
        });
      });

      if (response.status === 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Sending document"
        });
      }
    }
  },
}
</script>
<style lang="scss"></style>
